import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1d96c793 = () => interopDefault(import('../pages/AAHT.vue' /* webpackChunkName: "pages/AAHT" */))
const _47dd4a93 = () => interopDefault(import('../pages/ABBM.vue' /* webpackChunkName: "pages/ABBM" */))
const _3d560786 = () => interopDefault(import('../pages/caregiving.vue' /* webpackChunkName: "pages/caregiving" */))
const _583a8a07 = () => interopDefault(import('../pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _56d6d1f6 = () => interopDefault(import('../pages/de-et-culture.vue' /* webpackChunkName: "pages/de-et-culture" */))
const _026008ca = () => interopDefault(import('../pages/demo.vue' /* webpackChunkName: "pages/demo" */))
const _e928c012 = () => interopDefault(import('../pages/fot25.vue' /* webpackChunkName: "pages/fot25" */))
const _51fd6d7a = () => interopDefault(import('../pages/lmr.vue' /* webpackChunkName: "pages/lmr" */))
const _3c89520a = () => interopDefault(import('../pages/patient-insights.vue' /* webpackChunkName: "pages/patient-insights" */))
const _16c27a24 = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _65458250 = () => interopDefault(import('../pages/sign-on.vue' /* webpackChunkName: "pages/sign-on" */))
const _3d588e48 = () => interopDefault(import('../pages/stm-school.vue' /* webpackChunkName: "pages/stm-school" */))
const _2ef94182 = () => interopDefault(import('../pages/stmexperience.vue' /* webpackChunkName: "pages/stmexperience" */))
const _ef30704c = () => interopDefault(import('../pages/style.vue' /* webpackChunkName: "pages/style" */))
const _5e55db39 = () => interopDefault(import('../pages/theinfusion-referral-process.vue' /* webpackChunkName: "pages/theinfusion-referral-process" */))
const _50c728ae = () => interopDefault(import('../pages/travel-to-learn.vue' /* webpackChunkName: "pages/travel-to-learn" */))
const _0d4c9b18 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _a3ae75ac = () => interopDefault(import('../pages/williamsburg-aaht.vue' /* webpackChunkName: "pages/williamsburg-aaht" */))
const _2d0cdc8d = () => interopDefault(import('../pages/ymcarichmond.vue' /* webpackChunkName: "pages/ymcarichmond" */))
const _74a65e75 = () => interopDefault(import('../pages/ymcarichmond2.vue' /* webpackChunkName: "pages/ymcarichmond2" */))
const _74b475f6 = () => interopDefault(import('../pages/ymcarichmond3.vue' /* webpackChunkName: "pages/ymcarichmond3" */))
const _6734c4d4 = () => interopDefault(import('../pages/companies/add.vue' /* webpackChunkName: "pages/companies/add" */))
const _dd161e1a = () => interopDefault(import('../pages/projects/add.vue' /* webpackChunkName: "pages/projects/add" */))
const _26a94b0a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1fcb43ce = () => interopDefault(import('../pages/companies/_company.vue' /* webpackChunkName: "pages/companies/_company" */))
const _e1aeec0a = () => interopDefault(import('../pages/projects/_project/index.vue' /* webpackChunkName: "pages/projects/_project/index" */))
const _1abb1fcf = () => interopDefault(import('../pages/users/_user/index.vue' /* webpackChunkName: "pages/users/_user/index" */))
const _7e2b7c30 = () => interopDefault(import('../pages/users/_user/sign-up.vue' /* webpackChunkName: "pages/users/_user/sign-up" */))
const _57e419ac = () => interopDefault(import('../pages/projects/_project/participants/_participant.vue' /* webpackChunkName: "pages/projects/_project/participants/_participant" */))
const _f9a4834e = () => interopDefault(import('../pages/projects/_project/stories/_story.vue' /* webpackChunkName: "pages/projects/_project/stories/_story" */))
const _052814b0 = () => interopDefault(import('../pages/users/_user/_project/index.vue' /* webpackChunkName: "pages/users/_user/_project/index" */))
const _5084fb1e = () => interopDefault(import('../pages/users/_user/_project/journey.vue' /* webpackChunkName: "pages/users/_user/_project/journey" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/AAHT",
    component: _1d96c793,
    name: "AAHT"
  }, {
    path: "/ABBM",
    component: _47dd4a93,
    name: "ABBM"
  }, {
    path: "/caregiving",
    component: _3d560786,
    name: "caregiving"
  }, {
    path: "/companies",
    component: _583a8a07,
    name: "companies"
  }, {
    path: "/de-et-culture",
    component: _56d6d1f6,
    name: "de-et-culture"
  }, {
    path: "/demo",
    component: _026008ca,
    name: "demo"
  }, {
    path: "/fot25",
    component: _e928c012,
    name: "fot25"
  }, {
    path: "/lmr",
    component: _51fd6d7a,
    name: "lmr"
  }, {
    path: "/patient-insights",
    component: _3c89520a,
    name: "patient-insights"
  }, {
    path: "/projects",
    component: _16c27a24,
    name: "projects"
  }, {
    path: "/sign-on",
    component: _65458250,
    name: "sign-on"
  }, {
    path: "/stm-school",
    component: _3d588e48,
    name: "stm-school"
  }, {
    path: "/stmexperience",
    component: _2ef94182,
    name: "stmexperience"
  }, {
    path: "/style",
    component: _ef30704c,
    name: "style"
  }, {
    path: "/theinfusion-referral-process",
    component: _5e55db39,
    name: "theinfusion-referral-process"
  }, {
    path: "/travel-to-learn",
    component: _50c728ae,
    name: "travel-to-learn"
  }, {
    path: "/users",
    component: _0d4c9b18,
    name: "users"
  }, {
    path: "/williamsburg-aaht",
    component: _a3ae75ac,
    name: "williamsburg-aaht"
  }, {
    path: "/ymcarichmond",
    component: _2d0cdc8d,
    name: "ymcarichmond"
  }, {
    path: "/ymcarichmond2",
    component: _74a65e75,
    name: "ymcarichmond2"
  }, {
    path: "/ymcarichmond3",
    component: _74b475f6,
    name: "ymcarichmond3"
  }, {
    path: "/companies/add",
    component: _6734c4d4,
    name: "companies-add"
  }, {
    path: "/projects/add",
    component: _dd161e1a,
    name: "projects-add"
  }, {
    path: "/",
    component: _26a94b0a,
    name: "index"
  }, {
    path: "/companies/:company",
    component: _1fcb43ce,
    name: "companies-company"
  }, {
    path: "/projects/:project",
    component: _e1aeec0a,
    name: "projects-project"
  }, {
    path: "/users/:user",
    component: _1abb1fcf,
    name: "users-user"
  }, {
    path: "/users/:user/sign-up",
    component: _7e2b7c30,
    name: "users-user-sign-up"
  }, {
    path: "/projects/:project/participants/:participant?",
    component: _57e419ac,
    name: "projects-project-participants-participant"
  }, {
    path: "/projects/:project/stories/:story?",
    component: _f9a4834e,
    name: "projects-project-stories-story"
  }, {
    path: "/users/:user/:project",
    component: _052814b0,
    name: "users-user-project"
  }, {
    path: "/users/:user/:project/journey",
    component: _5084fb1e,
    name: "users-user-project-journey"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
